.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-signin {
  background-color: #a35ba5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #f4ab4d;
}

.App-link {
  color: #61dafb;
}

.bg-body-tertiary {
  background-color: #F16E10;
}

.bgimage {
  background-image: url("https://sixnapsis.com/wp-content/uploads/2023/09/patrick-shaun-tNX2qXglL6Q-unsplash-scaled.jpg");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg {
  background-color: "rgba(163,91,165,1.000)";
}

.navbar
{
    /* White tint with 0.5 opacity. */
    background-color: rgba(163,91,165,1);
    /* Notice how RGB of 255,255,255 is white. */
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  src: url(../node_modules/react-native-vector-icons/Fonts/Ionicons.ttf);
  font-family: "Ionicons";
}

@font-face {
  src: url(../node_modules/react-native-vector-icons/Fonts/FontAwesome.ttf);
  font-family: "FontAwesome";
}

@font-face {
  src: url(../node_modules/react-native-vector-icons/Fonts/FontAwesome5_Brands.ttf);
  font-family: "FontAwesome5_Brands";
  font-weight: 400; /* Regular weight */
  font-style: normal;
}

@font-face {
  src: url(../node_modules/react-native-vector-icons/Fonts/FontAwesome5_Regular.ttf);
  font-family: "FontAwesome5_Regular";
  font-weight: 400; /* Regular weight */
  font-style: normal;
}

@font-face {
  src: url(../node_modules/react-native-vector-icons/Fonts/FontAwesome5_Solid.ttf);
  font-family: "FontAwesome5_Solid";
  font-weight: 900; /* Bold weight for solid */
  font-style: normal;
}

@font-face {
  src: url(../node_modules/react-native-vector-icons/Fonts/MaterialIcons.ttf);
  font-family: "MaterialIcons";
}

@font-face {
  src: url(../node_modules/react-native-vector-icons/Fonts/Feather.ttf);
  font-family: "Feather";
}

@font-face {
  src: url(../node_modules/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf);
  font-family: "MaterialCommunityIcons";
}

@font-face {
  src: url(../node_modules/react-native-vector-icons/Fonts/Foundation.ttf);
  font-family: "Foundation";
}

@font-face {
  src: url(../node_modules/react-native-vector-icons/Fonts/Fontisto.ttf);
  font-family: "Fontisto";
}

.main {
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

}

.tabelement {
  align-items: center;
  justify-content: center;
  color: #a35ba5;
}
