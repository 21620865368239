.iconPicker{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
    padding: 10px;
    font-size: small;
}

.iconCard{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    border-radius: 10px;
/*    background-color: #a35ba5;*/
    background-color: white;
    color: black;
    cursor: pointer;
}

.leftcol {
    /* position: fixed;
     top: 0%;
     left: 0%; */
     border:solid 1px green;
     overflow:hidden;
  }
  
.rightcol {
     /* border:solid 1px red;
     position: relative;
     top: 0%;
     left: 50%;  */
     border:solid 1px #a35ba5; 
     overflow-y: scroll;
     height: 332px;
  }